<template>
  <div>
    <c-page-heading
      data-cy="header"
      :heading="$t('auth.layout.welcomeTo')"
      :subHeading="$t('auth.layout.gatherings')"
    ></c-page-heading>

    <v-container class="mt-10">
      <v-row class="justify-center">
        <v-col cols="12" :sm="8" class="my-3 text-center">
          <div class="login-card">
            <form>
              <div class="legacy-input-title">
                <h3 class="font-weight-light" data-cy="enterYourEmailAddressToRegisterOrLogin">
                  {{ $t('auth.email.enterYourEmailAddressToRegisterOrLogin') }}
                </h3>
              </div>
              <div>
                <input
                  @keyup.enter="onSubmit"
                  v-model="email"
                  type="email"
                  autocomplete="on"
                  id="email"
                  class="legacy-input"
                  placeholder="someone@example.com"
                  data-cy="email-input"
                />
                <p class="error--text mb-2" v-if="error.length > 0">
                  <small>{{ error }} </small>
                </p>
              </div>
              <v-btn
                type="submit"
                x-large
                @click.prevent="onSubmit"
                :disabled="isSubmitting"
                color="primary"
                rounded
                class="submit-btn-text submit-btn"
              >
                {{ $t('common.submit') }}
              </v-btn>
            </form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { isEmailAddressCheck } from '@/utilities/validations'

export default {
  data: () => {
    return {
      isSubmitting: false,
      email: '',
      error: '',
      allowedDomains: [
        'mesmerisevr.com',
        'mesmerise.it',
        'born.net',
        'metaphoric.games',
        'atmosphericxr.com',
        'iqxr.com',
      ],
    }
  },
  methods: {
    onSubmit() {
      if (this.isSubmitting || this.validate() !== true) {
        return
      }
      this.isSubmitting = true

      this.$store.dispatch('ui/toggleLoading', { isLoading: true })

      const emailDomain = this.email.substr(this.email.indexOf('@') + 1)
      if (!this.allowedDomains.includes(emailDomain.toLowerCase())) {
        this.$store.dispatch('ui/toggleLoading', { isLoading: false })
        this.$router.push('/error/403')
        return
      }

      this.$store.dispatch('auth/requestPasscode', { email: this.email }).then(
        () => {
          this.isSubmitting = false
          this.$store.dispatch('ui/toggleLoading', { isLoading: false })
          if (
            this.$route.query &&
            this.$route.query.ReturnUrl &&
            this.$route.query.ReturnUrl !== '/'
          ) {
            this.$router.push({
              path: '/account/passcode',
              query: { ReturnUrl: this.$route.query.ReturnUrl },
            })
          } else {
            this.$router.push('/account/passcode')
          }
        },
        error => {
          this.$store.dispatch('ui/toggleLoading', { isLoading: false })
          this.isSubmitting = false
          this.message =
            (error.response && error.response.data) || error.message || error.toString()
        }
      )
    },
    validate() {
      this.error = ''
      if (isEmailAddressCheck(this.email) !== true) {
        this.error = this.$t('validations.pleaseProvideAValidEmailAddress')
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss" scoped></style>
